import React, { FC, useEffect, useMemo, useState } from "react";
import yup from "../../yup-extended";
import { SubmitHandler, useForm } from "react-hook-form";
import { observer } from "mobx-react-lite";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";

import useStore from "../../hooks/useStore";
import { IReport } from "../../store/AdminStore";
import { yupResolver } from "@hookform/resolvers/yup";
import UserReportsList from "../user-reports-list/UserReportsList";
import MuiFormSelect from "../form-controls/MuiFormSelect";

export type IUserReportForm = Pick<
  IReport,
  "type_report" | "type_marketplace" | "url_report"
>;

interface IUserReportsFormProps {
  userId: number;
}

const UserReportsForm: FC<IUserReportsFormProps> = ({ userId }) => {
  const { adminStore, popupStore } = useStore();
  const [showAddReportForm, setShowAddReportForm] = useState(false);
  const [mode, setMode] = useState<"update" | "add">("update");

  const schema = yup
    .object({
      type_report: yup.string().required(),
      type_marketplace: yup.string().required(),
      url_report: yup.string().required(),
    })
    .required();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IUserReportForm>({
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<IUserReportForm> = (data) => {
    if (mode === "add") {
      adminStore
        .addReport({ ...data, user_id: userId })
        .then(() => {
          adminStore.getReports();
          popupStore.closePopup();
        })
        .catch(() => {});
    }

    if (mode === "update") {
      adminStore
        .updateReport({ ...data, user_id: userId })
        .then(() => {
          adminStore.getReports();
          popupStore.closePopup();
        })
        .catch(() => {});
    }
  };

  useEffect(() => {
    adminStore.getReports();
    adminStore.getMarketplaceTypes();
    adminStore.getReportTypes();
  }, []);

  const userReports = useMemo(
    () => adminStore.reports.filter((report) => report.user_id === userId),
    [userId, adminStore.reports]
  );

  const onToggleShowForm = () => {
    setValue("type_marketplace", "");
    setValue("type_report", "");
    setValue("url_report", "");
    setMode("add");
    setShowAddReportForm(true);
  };

  const onUpdateReport = (report: IReport) => {
    setValue("type_marketplace", report.type_marketplace);
    setValue("type_report", report.type_report);
    setValue("url_report", report.url_report);
    setMode("update");
    setShowAddReportForm(true);
  };

  const onDeleteReport = (report: IReport) => {
    adminStore
      .deleteReport({ ...report, user_id: userId })
      .then(() => {
        adminStore.getReports();
      })
      .catch(() => {});
  };

  const reportTypesOptions = useMemo(
    () =>
      adminStore.reportTypes.filter(
        (type) =>
          !userReports.find(
            (userReport) => userReport.type_report === type.value
          )
      ),
    [adminStore.reportTypes, userReports]
  );

  return (
    <Box width="700px">
      <Typography variant="h6" mb={3}>
        Редактирование отчетов
      </Typography>
      <UserReportsList
        reports={userReports}
        onUpdateReport={onUpdateReport}
        onDeleteReport={onDeleteReport}
      />
      <Button onClick={onToggleShowForm}>Добавить репорт</Button>
      {showAddReportForm && (
        <Box
          component="form"
          noValidate
          onSubmit={handleSubmit(onSubmit)}
          sx={{ mt: 1 }}
        >
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={6}>
              <MuiFormSelect
                label="Тип отчета"
                options={reportTypesOptions}
                error={errors.type_report}
                name="type_report"
                register={register}
              />
            </Grid>
            <Grid item xs={6}>
              <MuiFormSelect
                name="type_marketplace"
                options={adminStore.marketplaceTypes}
                label="Тип Магазин"
                register={register}
                error={errors.type_marketplace}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="none"
                fullWidth
                id="url_report"
                label="Ссылка на отчет"
                error={!!errors.url_report}
                helperText={errors.url_report?.message}
                {...register("url_report")}
              />
            </Grid>
          </Grid>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            {mode === "add" ? "Добавить" : "Обновить"}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default observer(UserReportsForm);
