import React, { createRef, FC, forwardRef } from "react";
import MaskedInput from "react-text-mask";
import { InputBaseComponentProps, OutlinedTextFieldProps } from "@mui/material";
import TextField from "@mui/material/TextField";

export const MaskTokensMap: Record<string, string | RegExp> = {
  "#": /\d/,
};

interface IMaskTextFieldProps {
  showMask?: boolean;
  mask: Array<string | RegExp> | string;
  placeholder?: string;
}

const MaskTextField: FC<IMaskTextFieldProps> = forwardRef(
  ({ showMask, mask, placeholder, ...other }, inputRef) => {
    console.log(placeholder, "placeholder");
    const transformedMask: Array<string | RegExp> = Array.isArray(mask)
      ? mask
      : mask.split("").map((i) => {
          if (MaskTokensMap[i]) {
            return MaskTokensMap[i];
          }

          return i;
        });

    return (
      <MaskedInput
        {...other}
        ref={(ref: any) => {
          if (inputRef && typeof inputRef === "function") {
            inputRef(ref ? ref.inputElement : null);
          }
        }}
        mask={transformedMask}
        placeholder={placeholder}
        showMask={showMask}
        guide={false}
      />
    );
  }
);

const MuiFormMaskedInput: FC<
  Omit<OutlinedTextFieldProps & IMaskTextFieldProps, "ref">
> = forwardRef(({ mask, showMask = false, placeholder, ...other }, ref) => {
  return (
    <TextField
      {...other}
      InputLabelProps={{ shrink: true }}
      InputProps={{
        inputComponent:
          MaskTextField as React.ElementType<InputBaseComponentProps>,
        inputProps: { mask, placeholder, showMask, ref },
      }}
    />
  );
});

export default MuiFormMaskedInput;
