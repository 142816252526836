import { FC, useEffect, useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import { observer } from "mobx-react";
import useStore from "../hooks/useStore";
import EmptyReports from "../components/empty-reports/EmptyReports";

const Dashboard: FC = () => {
  const {
    reportsStore: { reports, isLoading },
  } = useStore();

  const { push } = useHistory();
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    if (!id && reports && reports.length > 0) {
      push(`/dashboard/${reports[0].type_report}`);
    }
  }, [id, reports]);

  const report = useMemo(() => {
    if (!reports) {
      return null;
    }

    return reports?.find((item) => item.type_report === id);
  }, [id, reports]);

  if (reports !== null && !reports?.length && !isLoading) {
    return <EmptyReports />;
  }

  return (
    <iframe
      src={report?.url_report}
      style={{ border: "none", height: "100%", width: "100%" }}
      width="100%"
    ></iframe>
  );
};

export default observer(Dashboard);
